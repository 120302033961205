// Apex chart Scss

.apexcharts-canvas {
  
  // Tooltip Color
  .apexcharts-tooltip {
    color: black !important;
    &.dark {
      color: $white !important;
    }
  }
  // Toolbar Download Option Color
  .apexcharts-toolbar {
    .apexcharts-menu {
      .apexcharts-menu-item {
        color: $body-color;
      }
    }
  }
}

// apex charts tooltip
.apexcharts-xaxistooltip {
  // color: black !important;

  color: orange !important;
  font-weight: bolder !important; 
}

// client-retention Chart - legend position for left
#client-retention-chart {
  .apexcharts-canvas {
    .apexcharts-legend {
      left: -14px !important;
    }
  }
}
