.react-draggable, .cursor {
  cursor: move;
}
.no-cursor {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}

.react-draggable strong {
  background: #ddd;
  border: 1px solid #fff;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}

.shapeclass{
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 110px;
  height: 110px;
  margin: 10px;
  padding: 10px;
  float: left;
  color: #fff;
  cursor: pointer;
}

.circle {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 125px;
  height: 125px;
  margin: 10px;
  padding: 10px;
  float: left;
  color: #fff;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.07);
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
}

.ovalbend {
  width: 140px;
  height: 70px;
  border-radius: 40px;
  background: #fff;
  border: 1px solid #fff;
  float: left;
  margin: 10px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.07);
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
}

.ovallong {
  width: 100px;
  height: 200px;
  border-radius: 40px;
  background: #fff;
  border: 1px solid #fff;
  float: left;
  margin: 10px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.07);
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
}

span.seat_capacity {
  position: absolute;
  bottom: 5px;
  background: black;
  padding: 0px 5px;
  border-radius: 5px;
  opacity: 0.5;
}

.no-pointer-events {
  pointer-events: none;
}
.hovered {
  background-color: gray;
}


/*
  * RemWrapper needs to take it's styles from this element,
  * and this element can't have an absolute position after it's kicked in.
  * AFAIK it's not possible to do this directly in the RemWrapper component.
  */
.rem-position-fix {
  position: static !important;
}


.color {
  float: left;
  cursor: pointer;
  width: 48px;
  height: 48px;
}

.floor_selector{
  line-height: 48px;
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  width: 100%;
}

.floor_selector .button:first-child {
  border-left: none;
}

.button {
  cursor: pointer;
  border-left: solid 1px #9DA1AA;
  flex-grow: 3;
}

.active {
  background: #017e84;
  color: white;
}