@import './bootstrap.scss';
@import './components.scss';
@import './bootstrap-extended.scss';
@import './colors.scss';
@import './iconfont.scss';
@import './custom-rtl.scss';

.filter-container-left {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: black;
  z-index: 9999;
}
.terms {
  overflow: auto;
  height: 600px;
  user-select: none;
}

.css-10lvx9i-Input {
  color: black !important;
}

.background-transparent {
  background: transparent !important;
}

.background-none {
  background: none !important;
}

.map-box {
  width: 100%;
  height: 400px;
  min-height: 400px;
  max-height: 600px;
}

.card-header:first-child {
  border-radius: calc(0.5rem - 1px) !important;
}
.terms {
  overflow: auto;
  height: 600px;
  user-select: none;
}
.filter-container-left {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: black;
  z-index: 9999;
}

.btn.btn-icon.btn-small {
  padding: 0.3rem 0.4rem !important;
  margin-left: 5px;
}

.timezone-picker-list-rel {
  position: absolute;
  background-color: #fff;
  z-index: 100;
  max-height: 130px !important;
}
.main-menu {
  z-index: 1000 !important;
}
html body .content .content-wrapper {
  padding-bottom: 200px !important;
  margin-left: 15px !important;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.3)!important;
}

.css-1y83tjl {
  color: black !important;
}

.select__value-container input {
  color: black !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: #fff !important;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  color: black !important;
}
.btn-primary {
  color: #fff !important;
}
@font-face {
  font-family: 'Roboto Black';
  src: local('../../assets/fonts/Roboto/Roboto-Black.ttf') format('TrueType');
}
@media print {
  body {
    font-family: 'Roboto Black';
    font-size: 16px;
  }
  div.print_body {
    width: 80mm;
  }

  .print_body .card-body {
    padding: 0px !important;
  }
  .card-header {
    padding: 0px !important;
  }
}

body.dark-layout .apexcharts-canvas tspan {
  // fill: #000 !important;
  font-size: 10px;
}
.css-1n7v3ny-option{
  cursor: pointer !important;
}

