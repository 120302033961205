.data-table-extensions > .data-table-extensions-filter > .filter-text {
  color: white;
}

.rdt_Pagination {
  background-color: #4aa04a !important;
  color: white !important;
}

.rdt_Pagination button svg {
  fill: white !important;
}

.align-text-center {
  text-align: center;
}

.filter-container {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  right: 20px;
  top: 50px;
  background-color: #fff;
  color: white;
  z-index: 9999;
}

.data-table-extensions-action {
  z-index: 1000;
}
