table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

/* tr:nth-child(even) {
  background-color: #228b22;
  color: white;
} */
