// ================================================================================================
// 	File Name: app-ecommerce-shop.scss
// 	Description: SCSS file for ecommerce shop page.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy React Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core variables and mixins
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/mixins';
@import '../bootstrap-extended/mixins';

// Load variable overrides
@import '../core/variables/variables';
@import '~bootstrap/scss/_variables.scss';

// Overrides user variable
@import '../core/variables/components-variables';

$wishlist-bg: #f6f6f6;

.ecommerce-application {
  position: relative;
  height: 100%;

  div[role='navigation'] {
    top: 25px !important;
    width: 260px;
    overflow: visible !important;
  }

  .shop-content-overlay {
    position: absolute;
    top: 3.7rem;
    height: 100%;
    width: 100%;
    background: rgba($pure-black, 0.2);
    z-index: 0;
    opacity: 0;
    &.show {
      opacity: 1;
      z-index: 1;
    }
  }

  // for filter heading above sidebar
  .filter-heading {
    font-weight: 700;
    color: $body-color;
    position: relative;
    top: -7px;
  }
  // Shop Sidebar
  .sidebar-shop {
    width: $sidebar-width;
    z-index: 4;
    box-shadow: none !important;
    overflow: visible !important;
    & + div {
      display: none;
    }
    //close icon
    .sidebar-close-icon {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      font-size: 1.25rem;
      z-index: 5;
      cursor: pointer;
    }

    // for radio
    .vx-radio-con {
      input:checked ~ span {
        color: $primary;
      }
      .vx-radio {
        margin-right: 1rem;
      }
    }

    // for title
    .filter-title {
      font-weight: 700;
    }

    // for stars in ratingd
    .ratings-list {
      .ratings-list-item {
        font-size: 1.5rem;
        margin-right: 0.25rem;
        cursor: pointer;
      }
      & ~ .stars-received {
        margin-top: 0.35rem;
      }
    }
  }

  // shop main content
  .shop-content {
    margin-left: 300px;
  }
  //ecommerce header
  .ecommerce-header-items {
    display: flex;
    justify-content: space-between;
    .view-options {
      .React-Select {
        width: 16%;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
        .select__control {
          cursor: pointer;
          border: none;
        }
        .select__indicator-separator {
          display: none;
        }
        .select__menu {
          z-index: 2;
        }
      }
      .view-btn-option {
        display: inline-block;
        .btn.view-btn {
          margin-right: 10px;
          background-color: $white;
          padding: 0.5rem;
          color: $body-color;
          box-shadow: 0 2px 8px 0 rgba($pure-black, 0.14) !important;

          &.active {
            &:hover,
            &:active {
              color: $primary !important;
            }
            color: $primary !important;
          }
          &:hover,
          &:active {
            color: $body-color;
          }
        }
      }
    }
    .result-toggler {
      .shop-sidebar-toggler {
        svg {
          cursor: pointer;
        }
        padding-left: 0;
        &:active,
        &:focus {
          outline: 0;
        }
      }
      .search-results {
        font-weight: 700;
        color: $body-color;
        display: inline-block;
      }
    }
  }

  // Product search bar
  .search-product {
    height: 48px;
    border: none;
    box-shadow: 0 2px 8px 0 rgba($pure-black, 0.14);
    font-size: 1.2rem;
    padding-left: 1rem;
    & ~ .form-control-position {
      top: 4px;
      right: 10px;
    }
    &::placeholder {
      font-size: 0.95rem;
    }
  }

  // main Products
  .ecommerce-card {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 25px 0 rgba($pure-black, 0.25);
    }
    .item-price {
      font-weight: 700;
    }

    .item-name {
      font-weight: 600;
      color: $headings-color;
      a {
        color: $headings-color;
      }
    }
    .item-description {
      font-size: 0.875rem;
    }
    .wishlist,
    .cart {
      padding: 0.8rem 1rem;
      cursor: pointer;
      font-weight: 600;
      font-size: 0.875rem;
      text-transform: uppercase;
    }

    .wishlist {
      background-color: $wishlist-bg;
      color: $headings-color;
      user-select: none;
    }
    .cart {
      background-color: $primary;
      color: $white;
      .view-in-cart {
        color: $white;
      }
    }
  }

  .table-session {
    border: 1px solid #c5c1c178 !important;
    background-color: #babfc724 !important;
  }

  .card-content {
    // padding: 10px;
    background: $cyan;
    padding: 20px;
    border-radius: 10px;
  }

  .card-content span {
    font-size: 16px;
    color: white;
  }
  // margin for both views
  .grid-view,
  .list-view {
    margin-top: 0.7rem;
  }

  // Grid View
  .grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 28px;
    .ecommerce-card {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .card-content {
        .item-img {
          padding-top: 0.5rem;
          min-height: 15.85rem;
        }
        .item-wrapper {
          display: flex;
          justify-content: space-between;
          .item-price {
            position: relative;
            top: 5px;
          }
          .shipping {
            display: none;
          }
        }
        .item-company {
          display: none;
        }
        .item-options {
          display: flex;
          flex-wrap: wrap;
          .cart,
          .wishlist {
            flex-grow: 1;
          }
          .wishlist {
            padding: 0.8rem 2rem;
          }
          .item-wrapper {
            .item-rating {
              display: none;
            }
            .item-price {
              display: none;
            }
          }
        }
        .item-name {
          margin-top: 1rem;
        }
        .item-name,
        .item-description {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .card-body {
        padding: 1rem;
        padding-bottom: 0;
      }
    }
  }

  // List View
  .list-view {
    display: grid;
    grid-template-columns: 1fr;
    .ecommerce-card {
      overflow: hidden;
      .card-content {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        .item-img {
          align-self: center;
          padding-left: 1rem;
          padding-right: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        .card-body {
          padding: 1rem;
          padding-bottom: 0.5rem;
          border-right: 1px solid $gray-300;
          .item-rating {
            display: none;
          }
          .item-price {
            display: none;
          }
          .item-name {
            margin-bottom: 0.25rem;
            font-size: 0.95rem;
          }
          .item-company {
            display: block;
            cursor: pointer;
            font-size: 0.875rem;
            .company-name {
              color: $primary;
            }
          }
        }
        .item-options {
          padding: 1rem;

          .item-wrapper {
            position: relative;
            .item-rating {
              position: absolute;
              right: 0;
            }
            .item-cost {
              .item-price {
                position: relative;
                top: 55px;
                font-size: 16px;
              }
            }
          }
          .shipping {
            position: relative;
            top: 50px;
            color: $text-muted;
          }
          .wishlist {
            margin-top: 84px;
            margin-bottom: 10px;
          }
          .wishlist,
          .cart {
            border-radius: 6px;
          }
        }
      }
    }
  }

  // For CheckOut Wizard
  .vx-wizard {
    .step {
      background-color: transparent !important;
    }
  }

  // for checkout-items and options
  .checkout-items {
    .ecommerce-card {
      .card-content {
        .item-company {
          margin-bottom: 0;
        }
        .stock-status-in {
          color: $success;
          font-weight: 400;
          font-size: 0.875rem;
        }
        .stock-status-out {
          color: $danger;
          font-weight: 400;
          font-size: 0.875rem;
        }
        .item-quantity {
          font-weight: 700;
          font-size: 0.875rem;
          .quantity-title {
            margin-bottom: 0.5rem;
          }
          .quantity-counter-wrapper {
            margin-left: -10px;
          }
        }
        .offers {
          font-weight: 500;
          margin-bottom: 0;
          font-size: 1rem;
        }
        .offers {
          color: $success;
        }
      }
    }
  }
  .checkout-options {
    .options-title {
      color: $text-muted;
    }
    .coupons {
      display: flex;
      justify-content: space-between;
      .coupons-title {
        font-weight: 600;
        p {
          margin-bottom: 0.5rem;
        }
      }
      .apply-coupon {
        color: $primary;
        font-weight: 500;
        cursor: pointer;
        p {
          margin-bottom: 0.5rem;
        }
      }
    }
    .price-details {
      font-weight: 600;
      margin-bottom: 0.75rem;
    }
    .detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.75rem;
      .detail-title {
        color: $text-muted;
        &.detail-total {
          color: $body-color;
          font-weight: 600;
        }
      }
      .detail-amt {
        &.discount-amt {
          color: $success;
        }
        &.emi-details {
          color: $primary;
          cursor: pointer;
        }
        &.total-amt {
          font-weight: 600;
        }
      }
    }
  }
  .payment-type {
    .gift-card {
      cursor: pointer;
    }
  }

  // Ecommerce Search Z_INDEX
  .ecommerce-searchbar {
    .form-group {
      z-index: 1;
    }
  }

  // checkout wizard
  .wizard {
    &.checkout-tab-steps {
      .steps {
        ul {
          li {
            &.error > a {
              color: $primary;
              .step {
                border-color: $primary;
                background-color: $primary;
              }
            }
          }
        }
      }
      #checkout-address {
        .form-group {
          .error {
            color: $danger;
          }
        }
      }
    }
  }

  // Pagination Z_INDEX
  .ecommerce-pagination {
    .pagination {
      z-index: 0;
      .page-item {
        .page-link {
          z-index: 1;
        }
      }
    }
  }

  // Media queries to change grid columns in diffrent Screens and to hide search results below 768px
  @media (max-width: 1200px) {
    .app-content {
      overflow: scroll;
    }
  }

  @include media-breakpoint-down(md) {
    div[role='navigation'] {
      overflow: hidden !important;
    }

    .sidebar-shop {
      top: 27px !important;
      overflow: hidden !important;
      .card {
        border-radius: 0;
        padding-bottom: 100vh;
        .card-body {
          padding-bottom: 100% !important;
        }
      }
    }
    .ecommerce-header-items {
      .result-toggler {
        .shop-sidebar-toggler {
          position: relative;
          top: 1.2rem;
        }
      }
      .view-options {
        .React-Select {
          width: 21%;
        }
      }
    }
    .grid-view {
      grid-template-columns: 1fr 1fr;
    }
    .shop-content {
      margin-left: 0;
    }
    .ecommerce-searchbar {
      .form-group {
        z-index: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    // to hide search results
    .ecommerce-header-items {
      .result-toggler {
        .search-results {
          display: none;
        }
        .shop-sidebar-toggler {
          top: 0.5rem;
        }
      }

      .sidebar-shop {
        transform: translateX(-123%);
        transition: all 0.2s ease;
        z-index: 5;
        &.show {
          position: absolute;
          transform: translateX(0);
          transition: all 0.2s ease;
        }
      }
    }
    // List View
    .list-view {
      .ecommerce-card {
        .card-content {
          grid-template-columns: 1fr;
          .item-img {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
          // to remove card-body border
          .card-body {
            border: none;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    // shop sidebar
    .sidebar-shop {
      top: 0;
    }
    // Grid View
    .grid-view {
      grid-template-columns: 1fr;
    }
    .ecommerce-header-items {
      .React-Select {
        width: 50% !important;
      }
    }
  }

  // To hide toggler on lg and up and grid view for wishlist and list view for checkout
  @include media-breakpoint-up(lg) {
    .ecommerce-header-items {
      .shop-sidebar-toggler {
        display: none;
      }
    }
    .wishlist-items {
      &.grid-view {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    .product-checkout {
      &.list-view {
        grid-template-columns: 2fr 1fr;
        column-gap: 20px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .React-Select {
      width: 30% !important;
    }
  }
  // for below xs screens
  @media (max-width: 360px) {
    .sidebar-shop {
      top: 1.5rem;
    }
  }
}

// Details Page
.ratings.border-left {
  border-color: $gray-300;
}
.social-media-btns {
  margin-top: 30px;
}
.item-features {
  background-color: $body-bg;
}

// alternate ecommerce card
.details-page-swiper .swiper-slide {
  width: 247px;
  overflow: hidden;
  padding: 1.5rem;
  background-color: $body-bg;
  box-shadow: rgba($pure-black, 0.1) 0px 4px 18px 0px,
    rgba($pure-black, 0.08) 0px 5px 12px 0px !important;
  .img-container {
    margin: 2.2rem auto;
    width: 8rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Details Page swiper
.details-page-swiper {
  .swiper-container {
    padding: 1.5rem 3.5rem;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-image: none;
    width: 35px;

    color: $body-color;
    &.disabled {
      opacity: 0.5;
    }
    &:after {
      font-size: 1rem;
    }
  }
  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }
}

@media (max-width: 768px) {
  .details-page-swiper {
    .swiper-wrapper {
      justify-content: center;
    }
  }
}
@media (max-width: 375px) {
  .details-page-swiper {
    .swiper-wrapper {
      .swiper-slide {
        margin-right: 3rem;
      }
    }
  }
}

.floor-bg-design{
  background-color: black;
  opacity: 0.4;
  padding: 5px;
  border-radius: 5px;
}
