// Core variables and mixins
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/mixins';
@import '../bootstrap-extended/mixins';

// Load variable overrides
@import '../core/variables/variables';
@import '~bootstrap/scss/_variables.scss';

// Overrides user variable
@import '../core/variables/components-variables';

// dashboard-analytics
.sales-card {
  // img left
  .img-left {
    // width: 200px;
    width: 160px;
    position: absolute;
    top: 0;
    left: 0;
  }
  // img right
  .img-right {
    // width: 175px;
    width: 160px;
    position: absolute;
    top: 0;
    right: 0;
  }

  // backgound color to card
  &.bg-analytics {
    background: linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
    .avatar {
      margin-bottom: 2rem;
      svg {
        height: 28px;
        width: 28px;
      }
    }
  }
}

table.dashboard-table {
  tr th,
  tr td {
    &:first-child {
      padding-left: 2rem;
    }
    &:last-child {
      padding-right: 2rem;
    }
  }
  td {
    padding: 0.75rem;
  }
}

.support-tracker-card {
  .apexcharts-canvas {
    top: -40px;
  }
}

@media only screen and (max-width: 576px) {
  .sales-card {
    // img left, img-right
    .img-left,
    .img-right {
      width: 140px;
    }
  }
}
