.border-radius-2 {
  border-radius: 2rem !important;
}

.border-radius-0-5 {
  border-radius: 0.5rem !important;
}

.no-border {
  border: none !important;
}