.no-padding {
  padding: 0 !important;
}

.date-filter-container {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: black;
  z-index: 9999;
}

.time-picker-custom {
  height: 2.5rem;

  border: 1px solid gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
