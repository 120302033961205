.select-box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-box {
    width: 100%;
  }
  .icon {
    color: #ccc;
    position: absolute;
    right: 25px;
  }
}
