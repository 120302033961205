// Core variables and mixins
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/mixins';

// Core variables and mixins overrides
@import '../../core/variables/variables';
@import '~bootstrap/scss/_variables.scss';

// Overrides user variable
@import '../../core/variables/components-variables';

/********* Apex Charts *********/

.apexcharts-canvas {
  .apexcharts-xaxistooltip.light {
    color: black !important;
  }
  .apexcharts-theme-dark {
    .apexcharts-tooltip-text {
      color: $white !important;
    }
  }
}
