.live-orders {
  .live-order {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ccc;
    .info {
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      font-size: 0.95rem;
      .pl-1 {
        padding-left: 0.25rem !important;
      }
    }
    .bottom-margin-1 {
      margin-bottom: 1rem;
    }
  }
}

.order {
  .order-item {
    display: flex;
    .quantity {
      flex: 0.4;
    }
    .name {
      flex: 3;
    }
    .price {
      flex: 1
    }
  }
  .fixed-bottom-right {
    position: fixed;
    bottom: 0;
  }
}

.editable-status {
  display: flex;
  align-items: center;
  .form-control {
    height: auto;
    padding: 0;
    font-weight: bold;
    border: none;
  }
}

.editable-status-bottom {
  padding: 1rem;
  // background-color: #10163a;
  background-color: #fff;
  border-top: 1px solid #c2c6dc;
  bottom: 0;
  text-align: center;
  width: 100%;
  .status-buttons {
    display: flex;
    justify-content: center;
    .status-button {
      padding: 0.5rem !important;
      min-width: 50%;
      text-align: center;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

.status-button-card button {
  padding: 0.75rem !important;
  text-align: center;
}

.dropdown-toggle-icon {
  left: 0 !important;
}

.reason {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.filters {
  display: flex;
  justify-content: space-between;
}

.line-height-1-5 {
  width: 90% !important;
  line-height: 1.6 !important;
}

.driver-section {
	margin-top: 2rem;
	border-top: 1px solid white;
}