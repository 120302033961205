::-webkit-scrollbar{
    width: 5px
}

::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
    background-color: rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb:active{
    background-color: rgba(0,0,0,0.4);
}

::-webkit-scrollbar-track{
    background-color: rgba(0,0,0,0.1);
}

::-webkit-scrollbar-track:hover{
    background-color: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-track:active{
    background-color: rgba(0,0,0,0.3);
}
